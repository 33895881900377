import React from 'react'
import LinksComponent from '../components/links'
import linksBG from '../images/link-bg.jpg'
import "./layout.css"

export default function Links() {
    return (
        <div className="links-content-wrapper">
            <img src={linksBG} style={{display: "none"}}/>
            <LinksComponent />
        </div>
    )
}
