import React from 'react'
import MothButtons from '../components/MothButtons'
import woaO from '../images/woaO.png'
import instaO from '../images/instaO.png'
import fsGoldO from '../images/fs-goldO.png'
import socialJustice from '../images/social-justice.png'

export default function Links() {
    return (
        <div className="links-component-wrap">
               <MothButtons size="small" order={[
                    {to: "/home", label:  "Home"},
                    {to: "/curriculum-vitae", label: "CV"},
                    {to: "/portfolio", label:  "Portfolio"},
                    {to: "/contact", label:  "Contact"},
                    ]}/>
                <div className="buffer" />

            <div className="links-moths-wrap" >
                <div className="int-links-wrap">
                    <div className="links-imgs-wrap">
                        <a href="https://www.instagram.com/brad_fairchild/" target="_blank">
                            <img src={instaO} id="instaO-link" />
                        </a>
                        <a href="http://www.worldofanarchie.com/" target="_blank">
                            <img src={woaO} id="woaO-link"/>
                        </a>
                        <div className="links-imgs-wrap-second-row">
                            <a href="https://www.foundstages.org/" target="_blank">
                                <img src={fsGoldO} id="fsGoldO-link" />
                            </a>
                            <a href="https://hulshofschmidt.wordpress.com/2011/03/09/matthew-franck-lecture-hate-funded-by-koch-brothers/" target="_blank">
                                <img src={socialJustice} id="socialJustice-link" />
                            </a>
                        </div>
                    </div>
                </div>
             
            </div>
        </div>
    )
}
